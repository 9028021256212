import { Link } from 'gatsby'
import React from 'react'
import { cn, buildImageObj, isBrowser, isRetina } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import LazyBackground from '../components/lazyBackground'
import ProjectLinkWrapper from './projectLinkWrapper'
import { lsepFix } from '../lib/string-utils'
import _debounce from 'lodash.debounce'

class ProjectPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgWidth: 1200,
    }
  }

  onResize = () => {
    const imgWidth = this.getImgWidth()
    this.setState({
      imgWidth: imgWidth,
    })
  }

  getImgWidth = () => {
    const { columnSize, inFeaturedProjectsGrid } = this.props
    const isHalf = columnSize === 'is-half'
    const isThreeQuarters = columnSize === 'is-three-quarters'
    // const isHomePreviewGrid =
    let imgWidth
    if (isBrowser()) {
      const windowWidth = window.innerWidth
      if (windowWidth <= 1024) {
        // single column
        imgWidth = window.innerWidth
      } else if (windowWidth > 1024 && windowWidth <= 1400) {
        // two column
        imgWidth = windowWidth / 2
        if (inFeaturedProjectsGrid) {
          if (isHalf) {
            imgWidth = window.innerWidth / 2
          } else if (isThreeQuarters) {
            imgWidth = window.innerWidth * 0.75
          }
        }
      } else if (windowWidth > 1400) {
        // three column
        imgWidth = window.innerWidth / 3
        if (inFeaturedProjectsGrid) {
          if (isHalf) {
            imgWidth = window.innerWidth / 2
          } else if (isThreeQuarters) {
            imgWidth = window.innerWidth * 0.75
          }
        }
      }
      if (isRetina()) {
        imgWidth = imgWidth * 2
      }
    }
    return Math.floor(imgWidth)
  }

  render() {
    const project = this.props
    const loadOrder = this.props.loadOrder
    const title = project.title && project.title
    const location =
      (project._rawLocation && project._rawLocation.title) ||
      (project.location && project.location.title)
    const area =
      (project._rawArea && project._rawArea.title) || (project.area && project.area.title)
    const previewImage = project._rawPreviewImage
    const columnSize = this.props.columnSize
    const customTableItems = this.props.isKarte
      ? project._rawCustomTableItems
      : project.customTableItems
    const shownTableItems =
      customTableItems &&
      customTableItems
        .filter(item => item.shownInPreview === true)
        .map(_item => `${_item.title}: ${_item.value}`)
        .join(', ')
    const _shownTableItems = shownTableItems && lsepFix(shownTableItems)
    const _projectType = project.projectType && lsepFix(project.projectType)
    const _status = project.status.title || project._rawStatus.title
    // preview sizing
    const imgWidth = this.state.imgWidth
    const lgPreviewImgUrl =
      previewImage &&
      previewImage.asset &&
      imageUrlFor(buildImageObj(previewImage))
        .width(imgWidth)
        .height(Math.floor((9 / 16) * imgWidth))
        .url()
    const imgUrl =
      previewImage &&
      previewImage.asset &&
      imageUrlFor(buildImageObj(previewImage))
        .width(imgWidth)
        .height(Math.floor((9 / 16) * imgWidth))
        .url()
    const imgPlaceholderUrl =
      previewImage &&
      previewImage.asset &&
      imageUrlFor(buildImageObj(previewImage))
        .width(imgWidth)
        .height(Math.floor((9 / 16) * imgWidth))
        .quality(20)
        .blur(80)
        .url()

    return (
      <div className={`project-preview`}>
        <ProjectLinkWrapper
          project={project}
          useRawExternalLinkFile={this.props.useRawExternalLinkFile}
        >
          <div className="project-preview__title is-size-3 is-size-4-mobile">
            {title} <br />
            {location ? location : area}
          </div>
          <div className="load-blur-container">
            {this.props.isKarte ? (
              <div
                className="project-preview__bg-image"
                style={{ backgroundImage: `url(${imgUrl})` }}
              />
            ) : (
              <LazyBackground
                classes="project-preview__bg-image"
                src={
                  columnSize === 'is-half' || columnSize === 'is-three-quarters'
                    ? lgPreviewImgUrl
                    : imgUrl
                }
                placeholder={imgPlaceholderUrl}
                loadOrder={loadOrder}
                projectPreview
                alt={title}
              />
            )}
          </div>
          <div className="project-preview__description is-size-7">
            {columnSize === 'is-half' || columnSize === 'is-three-quarters' ? (
              <p>{`${_projectType}, ${
                _shownTableItems ? _shownTableItems + ',' : ''
              } ${_status}`}</p>
            ) : (
              <p>
                {`${_projectType}${_shownTableItems ? ', ' + _shownTableItems : ''}`} <br />
                {_status}
              </p>
            )}
          </div>
        </ProjectLinkWrapper>
      </div>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onResize()
    window.addEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
}

export default ProjectPreview
