import React from 'react'
import _debounce from 'lodash.debounce'
import { isBrowser } from '../lib/helpers'

class LazyBackground extends React.Component {
  state = { src: null, loadStatus: 'is-loading' }

  updateImages() {
    const { src, loadOrder } = this.props
    if (this.state.src !== src) {
      this.state.src !== this.state.placeholder && this.setState({ src: this.state.placeholder })
      this.state.loadStatus !== 'is-loading' && this.setState({ loadStatus: 'is-loading' })
      const imageLoader = new Image()
      imageLoader.src = src
      imageLoader.onload = () => {
        this.setState({ src: src })
        this.state.loadStatus !== 'is-loaded' && this.setState({ loadStatus: 'is-loaded' })
      }
    }
  }

  render() {
    const loadOrder = this.props.loadOrder
    const alt = this.props.alt ? this.props.alt : ''
    return (
      <div
        {...this.props}
        className={`lazy-bg ${this.props.classes} ${this.state.loadStatus}`}
        style={{ backgroundImage: `url(${this.state.src || this.state.placeholder})` }}
        role="img"
        aria-label={alt}
      />
    )
  }
  componentDidMount() {
    this.setState({
      placeholder: this.props.placeholder,
    })
    // const { src } = this.props
    // const imageLoader = new Image()
    // imageLoader.src = src
    // imageLoader.onload = () => {
    //   this.setState({ src, loadStatus: 'is-loaded' })
    // }
  }
  componentDidUpdate() {
    const { src, loadOrder, projectPreview } = this.props
    if (projectPreview) {
      // load first 9 images
      if (loadOrder <= 9) {
        setTimeout(() => {
          this.updateImages()
        }, loadOrder * 100)
      } else {
        // load rest after delay
        setTimeout(() => {
          this.updateImages()
        }, 1000)
      }
    } else {
      this.updateImages()
    }
  }
}

export default LazyBackground
