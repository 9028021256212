import React from 'react'
import { Link } from 'gatsby'

function ProjectLinkWrapper(props) {
  const { project, children } = props
  const {
    slug,
    isExternalLink,
    externalLinkUrl,
    externalLinkFile,
    _rawExternalLinkFile,
    useRawExternalLinkFile,
  } = project

  const renderExternalLink = () => {
    let file = useRawExternalLinkFile ? _rawExternalLinkFile : externalLinkFile
    let fileUrl = file?.asset?.url
    let url = externalLinkUrl ?? fileUrl
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  const renderInteralLink = () => {
    return <Link to={`/projekte/${slug.current}`}>{children}</Link>
  }

  return isExternalLink ? renderExternalLink() : renderInteralLink()
}

export default ProjectLinkWrapper
