import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import { SVGMap } from 'react-svg-map'
import 'react-svg-map/lib/index.css'
import { getLocationId, getLocationName } from '../lib/react-svg-map-utils'
import BerlinBrandenburg from '../images/react-svg-map/berlin-brandenburg/with-location-points'
import ProjectPreview from '../components/projectPreview'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SEO from '../components/seo'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'

export const query = graphql`
  query KarteQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      mapFirstProject {
        location {
          title
        }
      }
    }
    projects: allSanityProject(filter: { slug: { current: { ne: null } }, visible: { eq: true } }) {
      edges {
        node {
          id
          _rawArea
          _rawLocation
          _rawCategories
          _rawCustomTableItems
          _rawPreviewImage
          _rawSlug
          _rawStatus
          area {
            title
          }
          location {
            title
          }
          categories {
            title
          }
          status {
            title
          }
          projectType
          customTableItems {
            title
            value
          }
          heroSliderImages {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          slug {
            current
          }
          isExternalLink
          externalLinkUrl
          externalLinkFile {
            asset {
              url
            }
          }
          _rawExternalLinkFile
          visible
        }
      }
    }
  }
`

class KartePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pointedPath: null,
      focusedPath: null,
      clickedPath: null,
      clickedPathPin: null,
      lastPointPath: null,
      slideIndex: 0,
      updateCount: 0,
    }
    this.handlePathMouseOver = this.handlePathMouseOver.bind(this)
    this.handlePathMouseOut = this.handlePathMouseOut.bind(this)
    this.handlePathClick = this.handlePathClick.bind(this)
    this.handlePathFocus = this.handlePathFocus.bind(this)
    this.handlePathBlur = this.handlePathBlur.bind(this)
    this.onDotClick = this.onDotClick.bind(this)
  }

  // map
  initMapLocations = () => {
    const data = this.props.data
    const projectNodes =
      data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    const areasWithLocations = projectNodes.map(project => project.location && project.area.title)
    const els = document.getElementsByClassName('svg-map__path')
    Array.from(els).forEach(el => {
      const name = el.getAttribute('name')
      if (areasWithLocations.includes(name)) {
        el.classList.add('has-locations')
      }
      if (el.id.includes('location-pin')) {
        el.classList.add('is-location')
      } else {
        el.classList.add('is-region')
      }
    })
  }

  initFirstLocation = () => {
    const data = this.props.data
    const mapFirstProject = data && data.site.mapFirstProject
    const firstLocation = mapFirstProject.location.title
    this.setState({ clickedPath: firstLocation })
    const locEls = document.getElementsByClassName('is-location')
    Array.from(locEls).forEach(el => {
      const name = el.getAttribute('name')
      const id = el.getAttribute('id')
      if (name === firstLocation) {
        el.classList.add('is-location-selected')
        const regionId = id.split('--')[1]
        const region = document.getElementById(regionId)
        region && region.classList.add('is-selected')
      }
    })
  }

  handlePathMouseOver = e => {
    const pointedLocation = getLocationName(e)
    this.setState({ pointedLocation: pointedLocation })
  }

  handlePathMouseOut() {
    this.setState({ pointedLocation: null })
  }

  handlePathClick = e => {
    if (!e.currentTarget.id.includes('location-pin')) {
      e.preventDefault()
      return
    }
    const clickedPath = getLocationName(e)
    const clickedPathId = getLocationId(e)
    const els = document.getElementsByClassName('is-selected')
    while (els.length > 0) {
      els[0].classList.remove('is-selected')
    }
    e.target.classList.add('is-selected')
    this.setState({
      clickedPath: clickedPath,
    })
    this.slider.slickGoTo(0)
    const current = e.currentTarget
    this.setState({ lastPointPath: current.getAttribute('d') })
    if (current.getAttribute('d') !== this.state.lastPointPath) {
      const locEls = document.getElementsByClassName('is-location')
      Array.from(locEls).forEach(el => {
        el.classList.remove('is-location-selected')
      })
      current.classList.add('is-location-selected')
    }
    const regionId = clickedPathId.split('--')[1]
    const region = document.getElementById(regionId)
    region && region.classList.add('is-selected')
  }

  handlePathFocus = e => {
    const focusedLocation = getLocationName(e)
    this.setState({ focusedLocation: focusedLocation })
  }

  handlePathBlur() {
    this.setState({ focusedLocation: null })
  }

  // slider
  onDotClick = e => {
    this.slider.slickGoTo(e.target.getAttribute('data-index'))
    if (e.target.getAttribute('data-location') !== null) {
      const locationPin = e.target.getAttribute('data-location')
      this.setState({ clickedPathPin: locationPin })
    }
  }

  // mobile
  onMobileNextClick = () => {
    this.slider.slickNext()
  }

  render() {
    const { data, errors } = this.props
    const { clickedPath, isPinClicked, clickedPathPin, slideIndex } = this.state
    const projectNodes =
      data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    const filteredProjectNodes = projectNodes.filter(
      project => project.location.title === this.state.clickedPath
    )
    const currentProject = filteredProjectNodes[slideIndex] || filteredProjectNodes[0]
    // slider settings
    const NextArrow = props => {
      const { className, style, onClick } = props
      return (
        <div
          className={`${className} project-search-map__slider-next`}
          style={{ ...style }}
          onClick={onClick}
        >
          <span>→</span>
        </div>
      )
    }
    const sliderSettings = {
      fade: true,
      lazyLoad: true,
      initialSlide: 0,
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      speed: 400,
      nextArrow: <NextArrow />,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    }

    return (
      <>
        <SEO title="Karte" />
        <Helmet>
          <html className="is-footer-offset is-page-karte" />
          <body className="is-page-karte" />
        </Helmet>
        <section className={`section project-search-map columns is-marginless`}>
          <div className="project-search-map__map column is-half">
            <div className="project-search-map__hover-info">
              <span>
                {this.state.pointedLocation
                  ? `Berlin-Brandenburg, ${this.state.pointedLocation}`
                  : `Berlin-Brandenburg`}
              </span>
            </div>

            <div className="project-search-map__svg project-search-map__svg--desktop">
              <SVGMap
                map={BerlinBrandenburg}
                type="link"
                id="test"
                locationClassName="svg-map__path"
                onLocationMouseOver={this.handlePathMouseOver}
                onLocationMouseOut={this.handlePathMouseOut}
                onLocationClick={this.handlePathClick}
                onLocationFocus={this.handlePathFocus}
                onLocationBlur={this.handlePathBlur}
              />
            </div>

            <div className="project-search-map__mobile">
              <div className="project-search-map__mobile-header">
                {filteredProjectNodes && (
                  <div>
                    {clickedPath !== null ? (
                      <div>
                        <div className="project-search-map__title-next">
                          <div>
                            {currentProject && (
                              <div className="is-size-4">{currentProject.title}</div>
                            )}
                            {currentProject && (
                              <div className="is-size-4">
                                {currentProject.location.title || currentProject.area.title}
                              </div>
                            )}
                          </div>
                          <div>
                            {filteredProjectNodes.length > 1 && (
                              <button
                                className="btn project-search-map__mobile-next"
                                onClick={() => this.onMobileNextClick()}
                              >
                                <span>{`->`}</span>
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          {currentProject && (
                            <Link to={`/projekte/${currentProject.slug.current}`} className="btn">
                              Zum Projekt
                            </Link>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="is-size-4">Berlin Brandenburg</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <TransformWrapper defaultScale={1} defaultPositionX={200} defaultPositionY={100}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <TransformComponent>
                      <div className="project-search-map__svg project-search-map__svg--mobile">
                        <SVGMap
                          map={BerlinBrandenburg}
                          type="link"
                          id="test"
                          locationClassName="svg-map__path"
                          onLocationMouseOver={this.handlePathMouseOver}
                          onLocationMouseOut={this.handlePathMouseOut}
                          onLocationClick={this.handlePathClick}
                          onLocationFocus={this.handlePathFocus}
                          onLocationBlur={this.handlePathBlur}
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          </div>
          <div className="project-search-map__projects is-hidden-mobile column is-half">
            <div className="project-search-map__projects-slider">
              {filteredProjectNodes && clickedPath !== null && (
                <span className="project-search-map__slider-index">
                  {filteredProjectNodes.length > 1 &&
                    `${this.state.slideIndex + 1} / ${filteredProjectNodes.length}`}
                </span>
              )}
              <Slider {...sliderSettings} ref={slider => (this.slider = slider)}>
                {filteredProjectNodes &&
                  filteredProjectNodes.map(project => (
                    <div className="project-search-map__project">
                      <ProjectPreview {...project} isKarte={true} />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </section>
      </>
    )
  }
  componentDidMount() {
    this.initMapLocations()
    if (!isMobile) {
      this.initFirstLocation()
    }
  }
}

export default KartePage
